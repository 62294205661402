<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-button
        class="btn btn-gradient-primary mb-1 mr-50"
        @click="$router.go(-1)"
      >
        <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
      </b-button>
      <button class="btn btn-gradient-primary mb-1" @click="Submit()">
        {{ $t("saveData") }}
      </button>
      <b-row class="match-height">
        <!-- <b-col md="2"></b-col> -->
        <b-col md="8">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  รายละเอียดคูปองพิเศษ
                </h3>
              </div>
            </div>

            <div class="pl-2 pr-2 pt-1">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Coupon"
                  rules="required"
                >
                  <label for="Coupon"
                    >{{ $t("couponName") }} :
                    <span class="text-danger">*</span></label
                  >
                  <b-form-input
                    id="Coupon"
                    v-model="coupon_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('couponName')"
                  />
                  <span class="text-danger">{{ errors[0] ? 'จำเป็นต้องกรอก': '' }}</span>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Detail"
                  rules="required"
                >
                  <label for="textarea"
                    >{{ $t("termsAndConditions") }} :
                    <span class="text-danger">*</span></label
                  >
                  <b-form-textarea
                    id="textarea"
                    v-model="description"
                    :placeholder="$t('termsAndConditions')"
                    :state="errors.length > 0 ? false : null"
                    rows="3"
                  />
                  <span class="text-danger">{{ errors[0] ? 'จำเป็นต้องกรอก': ''}}</span>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group>
                <label for="promotion">{{ $t("mnemonicPhrase") }} :</label>
                <b-form-input
                  id="promotion"
                  v-model="Obj.note"
                  type="text"
                  :placeholder="$t('quantity')"
                />
              </b-form-group> -->

              <!-- <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Expire"
                  rules="required"
                >
                  <label for="expire"
                    >{{ $t("expiryDate") }} :
                    <span class="text-danger">*</span></label
                  >
                  <b-form-datepicker
                    id="expire"
                    v-model="Obj.expdate"
                    :state="errors.length > 0 ? false : null"
                    class="mb-1"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group> -->
              <b-form-group>
                  <label for="remarks"
                    >{{ $t("remarks") }} :</label
                  >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    :placeholder="$t('remarks')"
                    rows="3"
                  />
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Expire"
                  rules="required"
                >
                  <label for="discount"
                    >{{ $t("price") }} :
                    <span class="text-danger">*</span></label
                  >
                  <b-form-input
                    id="discount"
                    v-model="price"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    class="mb-1"
                    :placeholder="$t('quantity')"
                    @input="price = Math.abs(price)"
                  />
                  <span class="text-danger">{{ errors[0] || price === 0 ? 'จำเป็นต้องกรอก': ''}}</span>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="check">{{ $t("usageStatus") }}</label
                ><br />
                <b-form-checkbox
                  v-model="active"
                  name="check-button"
                  :value="true"
                  :unchecked-value="false"
                  switch
                  inline
                />
              </b-form-group>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('pricing') }}
              </h3>
            </div>
            <div class="p-2">
              <div class="row">
                <div class="col-md-6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {{ $t('truck') }}
                        </th>
                        <th scope="col">
                          {{ $t('kilo') }}.
                        </th>
                        <th scope="col">
                          {{ $t('cubic') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in car.slice().reverse()"
                        :key="item.id"
                      >
                      <!-- .slice().reverse() -->
                        <td>{{ item.name }}</td>

                        <td>
                          <b-form-input
                            v-model="item.kilo"
                            type="number"
                          />
                        </td>
                        <td>
                          <b-form-input
                            v-model="item.queue"
                            type="number"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {{ $t('ship') }}
                        </th>
                        <th scope="col">
                          {{ $t('kilo') }}.
                        </th>
                        <th scope="col">
                          {{ $t('cubic') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr
                        v-for="item in ship.slice().reverse() "
                        :key="item.id"
                      >
                      <!-- .slice().reverse() -->
                        <td>{{ item.name }}</td>

                        <td>
                          <b-form-input
                            v-model="item.kilo"
                            type="number"
                          />
                        </td>
                        <td>
                          <b-form-input
                            v-model="item.queue"
                            type="number"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-modal
        ref="my-group"
        :title="$t('key-76')"
        size="lg"
        @ok="CalGroupUser()"
      >
        <div class="income-order-search order-search w-100">
          <div class="anan-input search-input">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="search"
                type="text"
                :placeholder="$t('search')"
                class="anan-input__input"
              />
              <div class="anan-input__suffix">
                <i class="anan-input__clear-btn anan-icon" />
                <i class="anan-input__suffix-icon anan-icon">
                  <i class="fal fa-search" />
                </i>
              </div>
            </div>
          </div>
        </div>

        <b-table
          responsive="sm"
          :fields="fieldsList"
          :items="paginatedItems"
          :selectable="selectable"
          :checked-rows.sync="selectedRows"
          show-empty
        >
          <template #empty>
            <div class="text-center">
              <img
                src="@/assets/images/anan-img/empty/empty.png"
                alt="empty"
                width="180px"
              />
              <p>No Data</p>
            </div>
          </template>

          <template #cell(checkbox)="data">
            <b-form-checkbox v-model="data.item.checked" :value="true" />
          </template>
          <template #cell(index)="data">
            {{ data.item.itemnumber_text }}
          </template>
          <!-- <template #cell(nameTrans)="data">
            {{ data.item.nameTrans }}
          </template> -->
        </b-table>

        <div class="demo-spacing-0 d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="paginatedItems()"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BTable,
  BFormCheckbox,
  BModal,
  BPagination,
  BFormGroup,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BFormCheckbox,
    BModal,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormDatepicker,
    BFormTextarea,
  },
  data() {
    return {
      required,
      promotion: "",
      promotionRange: "",
      description: "",
      check: false,

      selectable: true,
      selectedRows: [],
      selectAll: false,

      // Table

      itemsGroup: [],
      items: [],

      itemsTrans: [],

      itemsList: [],
      perPage: 7,
      currentPage: 1,
      subrole: [],
      module_list: [],
      module_use: "",
      group_user_list: [],
      group_use: "",
      group_user_type: 1,
      group_userloading: false,
      category: "",
      search: null,
      totalRows: 1,
      ship: [],
      car: [],
      arr: [],
      coupon_name: null,
      description: null,
      price: 0,
      active: false,
      remarks: null,
      // waititem: [],
    };
  },
  computed: {

  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const { data: res } = await this.$http.get("/product_type/showlist");
        this.arr = res.data
        this.arr.sort((a, b) => {
            if (a.initial < b.initial) {
                return 1;
            } else if (a.initial > b.initial) {
                return -1;
            } else {
                return 0;
            }
        })
        this.car = this.arr.filter(ele => ele.type === 1)
        this.ship = this.arr.filter(ele => ele.type === 2)
      } catch (e) {
        console.log(e);
      }
    },
    modalTrans() {
      this.$refs["my-trans"].show();
    },
    modalGroup() {
      this.$refs["my-group"].show();
    },

    Submit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.price || Number(this.price) === 0) {
            return
          }
          const totalarr = [];
          this.arr.forEach(ele => {
            const obj = {
              product_type_id: ele.id,
              product_type_name: ele.name,
              product_type_detail: ele.detail,
              product_type_class: ele.type,
              product_type_kilo: ele.kilo,
              product_type_queue: ele.queue,
              product_type_initial: ele.initial,
              product_type_name_en: ele.name_en,
              product_type_name_cn: ele.name_cn,
            }
            totalarr.push(obj)
          })
          const Objnew = {
            coupon_name: this.coupon_name,
            description: this.description,
            price: this.price,
            active: this.active,
            remarks: this.remarks,
            product_type: totalarr,
          };
          this.$http
            .post("/coupon/storeandupdate/rate", Objnew)
            .then(response => {
              if(response.data.status){
                this.Success(this.$t("key-308"));
                this.$router.push({ name: "admin-coupon-rate" });
              } else {
                this.SwalError(response.data.message);
              }
              
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
            });
        }
      });
    },
    CalModule() {
      const moduleArr = this.module_use.sub_role.split(",");
      this.subrole.forEach((item) => {
        if (moduleArr.includes(item.level.toString())) {
          this.$set(item, "status", true);
        } else {
          this.$set(item, "status", false);
        }
      });
    },
    CalGroupUser() {
      const Checked = this.itemsList.filter((item) => item.checked);
      this.itemsGroup = Checked;
      this.waititem = [];
      // this.itemsGroup.forEach(item => {

      // })
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    showAdd() {
      this.$bvModal.show("add-addr");
    },
    showModal() {
      this.name = null;
      this.phone = null;
      this.address = null;
      this.province = null;
      this.district = null;
      this.subdistrict = null;
      this.postalcode = null;
      this.$bvModal.show("modal-1");
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
